@import url('https://fonts.googleapis.com/css2?family=Parisienne&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

.nameTag {
  font-family: 'Parisienne', cursive;
  /* font-family: 'Dancing Script', cursive; */
  font-size: 22px;
}

.roboto {
  font-family: 'Roboto', sans-serif;
}
